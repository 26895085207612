import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import HomeContactForm from '../components/homePage/HomePageContactForm.tsx';
import Logo from '../assets/MyFyeLogo2.png';

function TermsOfService() {
  const navigate = useNavigate();
  const isSmallScreen = window.innerWidth <= 768;
  
  return (
    <div className="terms-of-service-container">
      <div className="terms-of-service-header">
        <img src={Logo} alt="Myfye Logo" className="privacy-logo" style={{width: '200px', height:'auto'}} />
        <h1>Terms of Service</h1>
      </div>
      
      <div className="pdf-container">
        <iframe 
          src="/Terms of Service.pdf" 
          width="100%" 
          height="800px" 
          title="Terms Of Service"
          className="terms-of-service-pdf"
        />
      </div>
      
      <style jsx>{`
        .terms-of-service-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 2rem;
        }
        
        .terms-of-service-header {
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
        }
        
        .privacy-logo {
          height: 40px;
          margin-right: 1rem;
        }
        
        .pdf-container {
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        
        .terms-of-service-pdf {
          border: none;
        }
        
        @media (max-width: 768px) {
          .terms-of-service-container {
            padding: 1rem;
          }
          
          .terms-of-service-pdf {
            height: 600px;
          }
        }
      `}</style>
    </div>
  );
}

export default TermsOfService;
