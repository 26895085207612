import React, { useState, useEffect } from 'react';
import menuIcon from '../../assets/menuIcon.png';
import { useSelector } from 'react-redux';
import { 
    setPrivySolanaWalletReady,
    setSolanaPubKey
 } from '../redux/userWalletData.tsx';
import { useDispatch } from 'react-redux';
import {useSolanaWallets} from '@privy-io/react-auth/solana';


function PrivyUseSolanaWallets() {
    const {createWallet, ready, wallets} = useSolanaWallets();
    
    const dispatch = useDispatch();
    useEffect(() => {
    
        if (!wallets) {
            console.log('creating solana wallets');
            createWalletAsync(); // Call the async function
        }
    }, []);


    useEffect(() => {
        console.log('solana wallets', wallets);
        if (Array.isArray(wallets) && wallets.length > 0 && wallets[0].address) {
            dispatch(setSolanaPubKey(wallets[0].address));
        } else {
            console.log('no solana wallets found:', wallets);
            console.log('creating solana wallets');
createWalletAsync();
        }
    }, [wallets]);


    useEffect(() => {
        dispatch(setPrivySolanaWalletReady((ready)))
    }, [ready]);

    const createWalletAsync = async () => {
        try {
            await createWallet(); // Await the async function
        } catch (error) {
            console.error('Error ', error);
        }
    };

    return (
        <div>
        </div>
    );
}

export default PrivyUseSolanaWallets


